import { IconButton } from '@material-ui/core';
import {
  Button,
  ClickAwayListener,
  createTheme,
  Dialog,
  InputAdornment,
  MenuItem,
  Popper,
  TextField,
  ThemeProvider
} from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './PersonInput.module.scss';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { useWindowWidth } from '@react-hook/window-size';

export const PersonInput = ({
                              name,
                              setFieldValue,
                              multiModeHeading,
                              multipleValue,
                              value,
                              multiple = false,
                              disablePortal = true,
                              primaryColor = '#00454A'
                            }) => {
  const inputTheme = createTheme({
    shape: {
      borderRadius: 12
    },
    palette: {
      primary: {
        main: primaryColor
      }
    },
    input: {
      style: {
        cursor: 'pinter'
      }
    },
    typography: {
      fontFamily: '"Libre Franklin", sans-serif'
    }
  });
  const [adults, setAdults] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [childrenAges, setChildrenAges] = useState([]);
  const [multiValue, setMultiValue] = useState([
    { adults: 1, childrenAges: [] }
  ]);
  useEffect(() => {
    if (value) {
      setAdults(value.adults);
      if (value.children?.length) {
        setChildrenAges(value.children);
      }
    }
  }, [value]);
  const width = useWindowWidth();
  const getForm = (relative) => multiple ? (
    <div className={cn(styles.personSelect, 'font-libre', { relative })}>
      {multiValue.map((value, multiIndex) => (
        <React.Fragment key={multiIndex}>
          <div className={styles.multiModeHeading}>
            {multiModeHeading} {multiIndex + 1}
          </div>
          <div className="flex flex-col items-stretch" key={multiIndex}>
            <div>
              <span>Adults:</span>
              <div>
                <IconButton
                  size="large"
                  color="primary"
                  onClick={() => removeAdult(true, multiIndex)}
                >
                  <RemoveIcon className={styles.largeIcon} fontSize="large" />
                  <RemoveIcon className={styles.smallIcon} fontSize="medium" />
                </IconButton>
                {multiValue[multiIndex].adults}
                <IconButton
                  size="large"
                  color="primary"
                  onClick={() => addAdult(true, multiIndex)}
                >
                  <AddIcon className={styles.largeIcon} fontSize="large" />
                  <AddIcon className={styles.smallIcon} fontSize="medium" />
                </IconButton>
              </div>
            </div>
            <div>
              <span>Children:</span>
              <div>
                <IconButton
                  size="large"
                  color="primary"
                  onClick={() => removeChildren(true, multiIndex)}
                >
                  <RemoveIcon className={styles.largeIcon} fontSize="large" />
                  <RemoveIcon className={styles.smallIcon} fontSize="medium" />
                </IconButton>
                {multiValue[multiIndex].childrenAges?.length}
                <IconButton
                  size="large"
                  color="primary"
                  onClick={() => addChildren(true, multiIndex)}
                >
                  <AddIcon className={styles.largeIcon} fontSize="large" />
                  <AddIcon className={styles.smallIcon} fontSize="medium" />
                </IconButton>
              </div>
            </div>
            {!!multiValue[multiIndex].childrenAges?.length && (
              <div className="flex flex-col mb-2">
                {multiValue[multiIndex].childrenAges.map((childAge, index) => (
                  <div className="flex justify-between w-full mt-1" key={index}>
                    <span className="flex">Age child {index + 1}</span>
                    <div>
                      <TextField
                        select
                        value={childAge ?? 0}
                        name={'childAge'}
                        onChange={(event) =>
                          applyChildAge(event, index, true, multiIndex)
                        }
                        SelectProps={{
                          native: width < 1024,
                          MenuProps: {
                            disablePortal: false
                          }
                        }}
                      >
                        {Array.from(Array(18).keys()).map((i) => {
                          return width < 1024 ? (<option
                            key={i}
                            value={i}
                          >
                            {i}
                          </option>) : (
                            <MenuItem
                              sx={{ textAlign: 'center' }}
                              key={i}
                              value={i}
                            >
                              {i}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!!multiIndex && (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className="my-2"
                type="button"
                onClick={() => {
                  multiValue.splice(multiIndex, 1);
                  setMultiValue([...multiValue]);
                }}
              >
                Remove {multiModeHeading}
              </Button>
            )}
          </div>
        </React.Fragment>
      ))}
      <Button
        fullWidth
        className="my-2"
        color="primary"
        variant="contained"
        type="button"
        onClick={() =>
          setMultiValue([...multiValue, { adults: 1, childrenAges: [] }])
        }
      >
        ADD {multiModeHeading}
      </Button>
      {width < 1024 && (
        <Button
          variant="outlined"
          className="mt-2"
          type="button"
          onClick={() => setDialogOpen(false)}
        >
          OK
        </Button>
      )}
    </div>
  ) : (
    <div className={cn(styles.personSelect, 'font-libre', { relative })}>
      <div>
        <span>Adults:</span>
        <div>
          <IconButton
            size="large"
            color="primary"
            onClick={() => removeAdult(false)}
          >
            <RemoveIcon className={styles.largeIcon} fontSize="large" />
            <RemoveIcon className={styles.smallIcon} fontSize="medium" />
          </IconButton>
          {adults}
          <IconButton
            size="large"
            color="primary"
            onClick={() => addAdult(false)}
          >
            <AddIcon className={styles.largeIcon} fontSize="large" />
            <AddIcon className={styles.smallIcon} fontSize="medium" />
          </IconButton>
        </div>
      </div>
      <div>
        <span>Children:</span>
        <div>
          <IconButton
            size="large"
            color="primary"
            onClick={() => removeChildren(false)}
          >
            <RemoveIcon className={styles.largeIcon} fontSize="large" />
            <RemoveIcon className={styles.smallIcon} fontSize="medium" />
          </IconButton>
          {childrenAges.length}
          <IconButton
            size="large"
            color="primary"
            onClick={() => addChildren(false)}
          >
            <AddIcon className={styles.largeIcon} fontSize="large" />
            <AddIcon className={styles.smallIcon} fontSize="medium" />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-col">
        {!!childrenAges.length &&
          childrenAges.map((childAge, index) => (
            <div className="flex justify-between w-full mt-1" key={index}>
              <span className="flex">Age child {index + 1}</span>
              <div>
                <TextField
                  select
                  value={childAge ?? 0}
                  onChange={(event) => applyChildAge(event, index)}
                  SelectProps={{
                    MenuProps: {
                      disablePortal: false
                    }
                  }}
                >
                  {Array.from(Array(18).keys()).map((i) => {
                    return (
                      <MenuItem sx={{ textAlign: 'center' }} key={i} value={i}>
                        {i}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            </div>
          ))}
      </div>
      {width < 1024 && (
        <Button
          variant="outlined"
          className="mt-2"
          type="button"
          onClick={() => setDialogOpen(false)}
        >
          OK
        </Button>
      )}
    </div>
  );

  useEffect(() => {
    setFieldValue(`${name}`, { adults: adults, children: childrenAges });
  }, [childrenAges, adults]);
  useEffect(() => {
    setFieldValue(`${name}`, multiValue);
  }, [multiValue]);
  useEffect(() => {
    if (multipleValue?.length) {
      setMultiValue(multipleValue);
    }
  }, [multipleValue]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (event) {
      width > 1024
        ? setAnchorEl(anchorEl ? null : event.currentTarget)
        : setDialogOpen(!dialogOpen);
      event.preventDefault();
      window.parent.postMessage(
        JSON.stringify({
          height: 400
        }),
        '*'
      );
    } else {
      setAnchorEl(null);
    }
  };

  let open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const addAdult = (multiMode = false, index) => {
    if (!multiMode && adults + childrenAges.length < 9) {
      setAdults(+adults + 1);
    }
    if (multiMode && adults + childrenAges.length < 9) {
      multiValue[index].adults = +multiValue[index].adults + 1;
      setMultiValue([...multiValue]);
    }
  };
  const removeAdult = (multiMode = false, index) => {
    if (!multiMode && adults > 1) {
      setAdults(+adults - 1);
    }
    if (multiMode && multiValue[index].adults > 1) {
      multiValue[index].adults = +multiValue[index].adults - 1;
      setMultiValue([...multiValue]);
    }
  };

  const addChildren = (multiMode = false, index) => {
    if (!multiMode && adults + childrenAges.length < 9) {
      appendChildrenAgeSelection();
    }
    if (multiMode && adults + childrenAges.length < 9) {
      appendChildrenAgeSelection(true, index);
    }
  };
  const removeChildren = (multiMode = false, index) => {
    if (childrenAges.length) {
      removeChildAgeSelection();
    }
    if (multiMode && multiValue[index].childrenAges.length) {
      removeChildAgeSelection(true, index);
    }
  };

  const appendChildrenAgeSelection = (multiMode = false, index) => {
    if (!multiMode) {
      setChildrenAges([...childrenAges, 0]);
    } else {
      multiValue[index].childrenAges = [...multiValue[index].childrenAges, 0];
      setMultiValue([...multiValue]);
    }
  };
  const removeChildAgeSelection = (multiMode = false, index) => {
    if (!multiMode) {
      childrenAges.pop();
      setChildrenAges([...childrenAges]);
    } else {
      multiValue[index].childrenAges.pop();
      setMultiValue([...multiValue]);
    }
  };

  const applyChildAge = (event, index, multiMode = false, multiIndex) => {
    if (!multiMode) {
      childrenAges[index] = event.target.value;
      setChildrenAges([...childrenAges]);
    } else {
      multiValue[multiIndex].childrenAges[index] = event.target.value;
      setMultiValue([...multiValue]);
    }
  };

  const calculateAdults = () => {
    return multiValue.length > 1
      ? multiValue.reduce((prev, curr) => prev + +curr.adults, 0)
      : multiValue[0]?.adults;
  };
  const calculateChildren = () => {
    return multiValue.length > 1
      ? multiValue.reduce((prev, curr) => prev + curr.childrenAges.length, 0)
      : multiValue[0]?.childrenAges?.length;
  };

  return (
    <ThemeProvider theme={inputTheme}>
      <ClickAwayListener
        onClickAway={(evt) => {
          if (evt.target.name !== name && evt.target.tagName !== 'BODY') {
            handleClick();
          }
        }}
      >
        <div className="w-full">
          <TextField
            fullWidth
            value={
              multiple
                ? `${multiValue.length} ${multiModeHeading}${
                  multiValue.length > 1 ? 's' : ''
                } (${calculateAdults()} ${
                  calculateAdults() > 1 ? 'Adults' : 'Adult'
                }${
                  calculateChildren() > 0 ? ', ' + calculateChildren() : ''
                } ${
                  calculateChildren() <= 0
                    ? ''
                    : calculateChildren() > 1
                      ? 'Children'
                      : 'Child'
                })`
                : `${childrenAges.length + adults} Person${
                  childrenAges.length + adults >= 2 ? 's' : ''
                }`
            }
            className={styles.personInput}
            onClick={handleClick}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ExpandMoreIcon />
                </InputAdornment>
              )
            }}
            label={'Passengers'}
            name={name}
            aria-describedby={id}
          />
          {width > 1024 && (
            <Popper
              style={{ zIndex: 100 }}
              disablePortal={disablePortal}
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement="bottom-start"
            >
              {getForm(false)}
            </Popper>
          )}
          {width <= 1024 && (
            <Dialog
              open={dialogOpen}
              fullWidth={true}
              onBackdropClick={() => setDialogOpen(false)}
            >
              {getForm(true)}
            </Dialog>
          )}
        </div>
      </ClickAwayListener>
    </ThemeProvider>
  );
};
